/*
 * @Author: your name
 * @Date: 2021-11-24 14:48:01
 * @LastEditTime: 2021-11-24 14:48:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /LittleBeeSaas/src/router/payment.js
 */
export default [
	{
		path: '/ChargeStandardManage',
		name: "ChargeStandardManage",
		title: "收费标准管理",
		icon: 'form',
		hide: false,
		component: resolve => require(['@/views/Payment/ChargeStandardManage'], resolve),
		meta: {title: '收费标准管理'},
	},
	{
		path: '/ChargeStandardBind',
		name: "ChargeStandardBind",
		title: "收费标准绑定",
		icon: 'sliders',
		hide: false,
		component: resolve => require(['@/views/Payment/ChargeStandardBind'], resolve),
		meta: {title: '收费标准绑定'},
	},
	{
		path: '/PrepayManage',
		name: "PrepayManage",
		title: "预缴管理",
		icon: 'book',
		hide: false,
		component: resolve => require(['@/views/Payment/PrepayManage'], resolve),
		meta: {title: '预缴管理'},
	},
	{
		path: '/PayPlan',
		name: "PayPlan",
		title: "缴费计划",
		icon: 'transaction',
		hide: false,
		component: resolve => require(['@/views/Payment/PayPlan'], resolve),
		meta: {title: '缴费计划'},
	},
	{
		path: '/BillManage',
		name: "BillManage",
		title: "账单管理",
		icon: 'audit',
		hide: false,
		component: resolve => require(['@/views/Payment/BillManage'], resolve),
		redirect: '/Payment/ActivityManage',
		meta: {title: '账单管理'},
		children: [
			{
				path: '/BillManage/PayInfo',
				name: "PayInfo",
				title: "账单明细",
				hide: false,
				component: resolve => require(['@/views/Payment/BillManage/_payInfo'], resolve),
				meta: {title: '账单明细'},
			},
			{
				path: '/BillManage/PayList',
				name: "PayList",
				title: "缴费明细",
				hide: true,
				component: resolve => require(['@/views/Payment/BillManage/_payList'], resolve),
				meta: {title: '缴费明细'},
			},
			{
				path: '/BillManage/OweList',
				name: "OweList",
				title: "欠费明细",
				hide: true,
				component: resolve => require(['@/views/Payment/BillManage/_oweList'], resolve),
				meta: {title: '欠费明细'},
			},
			{
				path: '/BillManage/PayRateReport',
				name: "PayRateReport",
				title: "收缴率报表",
				hide: true,
				component: resolve => require(['@/views/Payment/BillManage/_payRateReport'], resolve),
				meta: {title: '收缴率报表'},
			},
			{
				path: '/BillManage/RefundRecord',
				name: "RefundRecord",
				title: "退款记录",
				hide: true,
				component: resolve => require(['@/views/Payment/BillManage/_refundRecord'], resolve),
				meta: {title: '退款记录'},
			},
			{
				path: '/BillManage/BillNotice',
				name: "BillNotice",
				title: "账单通知",
				hide: true,
				component: resolve => require(['@/views/Payment/BillManage/_billNotice'], resolve),
				meta: {title: '账单通知'},
			},
		]
	},
]