
export default [
	// {
	// 	path: '/Console',
	// 	name: "Console",
	// 	title: "控制台",
	// 	hide: false,
	// 	icon: 'container',
	// 	component: resolve => require(['@/views/Basic/Console'], resolve),
	// 	meta: {title: '控制台'}
	// },
	{
		path: '/Employee',
		name: "Employee",
		title: "员工管理",
		icon: 'team',
		hide: false,
		component: resolve => require(['@/views/Basic/Employee'], resolve),
		meta: {title: '员工管理'}
	},
	{
		path: '/authority',
		name: "Authority",
		title: "权限管理",
		icon: 'appstore',
		hide: false,
		component: resolve => require(['@/views/Basic/Authority'], resolve),
		meta: {title: '权限管理'},
		// children: [
		// 	{
		// 		path: '/Authority/_details',
		// 		name: "Authority_details",
		// 		title: "账单管理-详情",
		// 		icon: 'profile',
		// 		hide: true,
		// 		component: resolve => require(['@/views/Basic/Authority/_details'], resolve),
		// 		meta: {title: '账单管理-详情'},
		// 	}
		// ]
	},
	{
		path: '/EstateInfo',
		name: "EstateInfo",
		title: "小区信息",
		icon: 'home',
		hide: false,
		component: resolve => require(['@/views/Basic/EstateInfo'], resolve),
		redirect: '/EstateInfo/BuildingManage',
		meta: {title: '小区信息'},
		children: [
			{
				path: '/EstateInfo/BuildingManage',
				name: "BuildingManage",
				title: "楼栋管理",
				hide: false,
				component: resolve => require(['@/views/Basic/EstateInfo/_buildingManage'], resolve),
				meta: {title: '楼栋管理'},
			},
			{
				path: '/EstateInfo/HouseManage',
				name: "HouseManage",
				title: "房屋管理",
				hide: false,
				component: resolve => require(['@/views/Basic/EstateInfo/_houseManage'], resolve),
				meta: {title: '房屋管理'},
			},
			{
				path: '/EstateInfo/HouseHoldInfo',
				name: "HouseHoldInfo",
				title: "住户信息",
				hide: false,
				component: resolve => require(['@/views/Basic/EstateInfo/_houseHoldInfo'], resolve),
				meta: {title: '住户信息'},
			},
			{
				path: '/EstateInfo/HouseHoldAudit',
				name: "HouseHoldAudit",
				title: "住户审核",
				hide: false,
				component: resolve => require(['@/views/Basic/EstateInfo/_houseHoldAudit'], resolve),
				meta: {title: '住户审核'},
			},
		]
	},
	{
		path: '/Workorder',
		name: "Workorder",
		title: "工单管理",
		icon: 'solution',
		hide: false,
		component: resolve => require(['@/views/Basic/WorkOrder'], resolve),
		meta: {title: '工单管理'}
	},
	{
		path: '/Forbidden',
		name: "Forbidden",
		title: "违禁字管理",
		icon: 'stop',
		hide: false,
		component: resolve => require(['@/views/Basic/Forbidden'], resolve),
		meta: {title: '违禁字管理'}
	},
	// {
	//     path: '/attendance',
	//     name: "Attendance",
	//     title: "考勤管理",
	//     icon: 'reconciliation',
	//     hide: false,
	//     redirect: '/attendance/record',
	//     component: resolve => require(['@/views/Basic/Attendance'], resolve),
	//     meta: {title: '考勤管理'},
	//     children: [
	//         {
	//             path: '/attendance/record',
	//             name: "Attendance_record",
	//             title: "考勤记录",
	//             hide: false,
	//             component: resolve => require(['@/views/Basic/Attendance/_record'], resolve),
	//             meta: {title: '考勤记录'},
	//         },
	//         {
	//             path: '/attendance/application',
	//             name: "Attendance_application",
	//             title: "申请记录",
	//             hide: false,
	//             component: resolve => require(['@/views/Basic/Attendance/application'], resolve),
	//             meta: {title: '申请记录'},
	//         },
	//         {
	//             path: '/attendance/attendanceteam',
	//             name: "Attendance_attendanceteam",
	//             title: "考勤小组",
	//             hide: false,
	//             component: resolve => require(['@/views/Basic/Attendance/_attendanceteam'], resolve),
	//             meta: {title: '考勤小组'},
	//         },
	//         {
	//             path: '/attendance/scheduling',
	//             name: "Attendance_scheduling",
	//             title: "排班计划",
	//             hide: false,
	//             component: resolve => require(['@/views/Basic/Attendance/_scheduling'], resolve),
	//             meta: {title: '排班计划'},
	//         },

	//     ]
	// }
]