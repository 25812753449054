import store from '@/store'
import axios from 'axios'
import qs from 'qs'
export default {
	install: function (Vue) {
		//axios
		Vue.prototype.$axios = axios
		Vue.prototype.qs = qs
		// 上传
		Vue.prototype.$upload = process.env.VUE_APP_URL + store.getters.getCommunityCode + '/manage/user/upload/uploadImg'
		// 上传员工/房屋/住户/单元/楼栋EXCEL
		Vue.prototype.$excelUpload = process.env.VUE_APP_URL + store.getters.getCommunityCode + '/manage/user/importExcel/importManageUserExcel'
		Vue.prototype.$houseUpload = process.env.VUE_APP_URL + store.getters.getCommunityCode + '/manage/user/importExcel/importEstateExcel'
		Vue.prototype.$residentUpload = process.env.VUE_APP_URL + store.getters.getCommunityCode + '/manage/user/importExcel/importResidentExcel'
		Vue.prototype.$unitUpload = process.env.VUE_APP_URL + store.getters.getCommunityCode + '/manage/user/importExcel/importUnitExcel'
		Vue.prototype.$buildingUpload = process.env.VUE_APP_URL + store.getters.getCommunityCode + '/manage/user/importExcel/importBuildingExcel'
		// 静态地址
		Vue.prototype.$ImgUrl = (src) => {
			return process.env.VUE_APP_STATIC + store.getters.getCommunityCode + '/' + src
		}
		// 时间格式化
		Vue.prototype.formatDate = (time, fmt) => {
			if (time > 0) {
				let date = new Date(time * 1000)
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
				}
				let o = {
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'h+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds()
				}
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
					}
				}
				return fmt
			} else {
				return ''
			}
		}
	}
}
