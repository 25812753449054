/*
 * @Author: your name
 * @Date: 2021-11-18 17:23:13
 * @LastEditTime: 2021-11-22 15:46:49
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/getters.js
 */
const getters = {
	getSelectedKeys(state) {
		return state.selectedKeys
	},
	getOpenKeys(state) {
		return state.openKeys
	},
	getToken(state) {
		return state.token
	},
	getCommunityCode(state) {
		return state.communityCode
	},
	getCity(state) {
		return state.cityList
	},
	getUserInfo(state) {
		return state.userInfo
	}
}

export default getters