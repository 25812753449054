/*
 * @Author: your name
 * @Date: 2021-11-18 17:22:21
 * @LastEditTime: 2021-11-22 15:45:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/actions.js
 */
const actions = {
	setToken: ({commit}, log) => {
		commit('setToken', log)
	},
	setCommunityCode: ({commit}, log) => {
		commit('setCommunityCode', log)
	},
	setSelectedKeys: ({commit}, log) => {
		commit('setSelectedKeys', log)
	},
	setOpenKeys: ({commit}, log) => {
		commit('setOpenKeys', log)
	},
	setCity: ({commit}, log) => {
		commit('setCity', log)
	},
	setUserInfo: ({commit}, log) => {
		commit('setUserInfo', log)
	},
}

export default actions