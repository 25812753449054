/*
 * @Author: your name
 * @Date: 2021-11-24 14:52:24
 * @LastEditTime: 2021-11-24 14:52:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /LittleBeeSaas/src/router/operation.js
 */
export default [
	{
		path: '/ClientSet',
		name: "ClientSet",
		title: "用户端设置",
		icon: 'setting',
		hide: false,
		component: resolve => require(['@/views/Operation/clientSet'], resolve),
		meta: {title: '用户端设置'},
	},
	{
		path: '/InspectionManage',
		name: "InspectionManage",
		title: "巡检管理",
		icon: 'retweet',
		hide: false,
		component: resolve => require(['@/views/Operation/InspectionManage'], resolve),
		redirect: '/InspectionManage/InspectionPoint',
		meta: {title: '巡检管理'},
		children: [
			{
				path: '/InspectionManage/InspectionPoint',
				name: "InspectionPoint",
				title: "巡检点",
				hide: false,
				component: resolve => require(['@/views/Operation/InspectionManage/_inspectionPoint'], resolve),
				meta: {title: '巡检点'},
			},
			{
				path: '/InspectionManage/InspectionRoute',
				name: "InspectionRoute",
				title: "巡检路线",
				hide: false,
				component: resolve => require(['@/views/Operation/InspectionManage/_inspectionRoute'], resolve),
				meta: {title: '巡检路线'},
			},
			{
				path: '/InspectionManage/InspectionPlan',
				name: "InspectionPlan",
				title: "巡检计划",
				hide: false,
				component: resolve => require(['@/views/Operation/InspectionManage/_inspectionPlan'], resolve),
				meta: {title: '巡检计划'},
			},
			{
				path: '/InspectionManage/InspectionExecute',
				name: "InspectionExecute",
				title: "巡检记录",
				hide: false,
				component: resolve => require(['@/views/Operation/InspectionManage/_inspectionExecute/index.vue'], resolve),
				meta: {title: '巡检记录'},
			},
		]
	},
	{
		path: '/Activity',
		name: "Activity",
		title: "活动",
		icon: 'notification',
		hide: false,
		component: resolve => require(['@/views/Operation/Activity'], resolve),
		redirect: '/Activity/ActivityManage',
		meta: {title: '活动'},
		children: [
			{
				path: '/Activity/ActivityManage',
				name: "ActivityManage",
				title: "活动管理",
				hide: false,
				component: resolve => require(['@/views/Operation/Activity/_activityManage'], resolve),
				meta: {title: '活动管理'},
			},
			{
				path: '/Activity/Organizers',
				name: "Organizers",
				title: "活动主办方",
				hide: false,
				component: resolve => require(['@/views/Operation/Activity/_organizers'], resolve),
				meta: {title: '活动主办方'},
			},
		]
	},
	{
		path: '/Announcement',
		name: "Announcement",
		title: "公告",
		icon: 'file-search',
		hide: false,
		component: resolve => require(['@/views/Operation/Announcement'], resolve),
		meta: {title: '公告'},
	},
	{
		path: '/News',
		name: "News",
		title: "资讯",
		icon: 'global',
		hide: false,
		component: resolve => require(['@/views/Operation/News'], resolve),
		redirect: '/News/',
		meta: {title: '资讯'},
		children:  [
			{
				path: '/News/NewsManage',
				name: "NewsManage",
				title: "资讯管理",
				hide: false,
				component: resolve => require(['@/views/Operation/News/_newsManage'], resolve),
				meta: {title: '资讯管理'},
			},
			{
				path: '/News/NewsItem',
				name: "NewsItem",
				title: "资讯分类",
				hide: false,
				component: resolve => require(['@/views/Operation/News/_newsItem'], resolve),
				meta: {title: '资讯分类'},
			},
		]
	},
	{
		path: '/TaskManage',
		name: "TaskManage",
		title: "任务",
		icon: 'form',
		hide: false,
		component: resolve => require(['@/views/Operation/TaskManage'], resolve),
		redirect: '/TaskManage/TaskList',
		meta: {title: '任务'},
		children: [
			{
				path: '/TaskManage/TaskList',
				name: "TaskList",
				title: "任务管理",
				hide: false,
				component: resolve => require(['@/views/Operation/TaskManage/_taskList'], resolve),
				meta: {title: '任务管理'},
			},
			{
				path: '/TaskManage/TaskSet',
				name: "TaskSet",
				title: "任务设置",
				hide: false,
				component: resolve => require(['@/views/Operation/TaskManage/_taskSet'], resolve),
				meta: {title: '任务设置'},
			},
		]
	},
	{
		path: '/BBS',
		name: "BBS",
		title: "社区",
		icon: 'home',
		hide: false,
		component: resolve => require(['@/views/Operation/BBS'], resolve),
		redirect: '/BBS/Dynamic',
		meta: {title: '社区'},
		children: [
			{
				path: '/BBS/Dynamic',
				name: "Dynamic",
				title: "社区动态",
				hide: false,
				component: resolve => require(['@/views/Operation/BBS/_dynamic'], resolve),
				meta: {title: '社区动态'},
			},
			{
				path: '/BBS/DynamicDetail',
				name: "DynamicDetail",
				title: "社区动态详情",
				hide: true,
				component: resolve => require(['@/views/Operation/BBS/_dynamic/detail'], resolve),
				meta: {title: '社区动态详情'},
			},
			{
				path: '/BBS/Topic',
				name: "Topic",
				title: "社区话题",
				hide: false,
				component: resolve => require(['@/views/Operation/BBS/_topic'], resolve),
				meta: {title: '社区话题'},
			},
		]
	},
	{
		path: '/Recommand',
		name: "Recommand",
		title: "建议咨询",
		icon: 'question-circle',
		hide: false,
		component: resolve => require(['@/views/Operation/Recommand'], resolve),
		meta: {title: '建议咨询'},
	},
	{
		path: '/Suggestion',
		name: "Suggestion",
		title: "便民电话",
		icon: 'phone',
		hide: false,
		component: resolve => require(['@/views/Operation/Suggestion'], resolve),
		meta: {title: '便民电话'},
	},
	{
		path: '/CommunityIntro',
		name: "CommunityIntro",
		title: "社区介绍",
		icon: 'snippets',
		hide: false,
		component: resolve => require(['@/views/Operation/CommunityIntro'], resolve),
		meta: {title: '社区介绍'},
	},
	{
		path: '/Facility',
		name: "Facility",
		title: "设施设备",
		icon: 'solution',
		hide: false,
		component: resolve => require(['@/views/Operation/Facility'], resolve),
		redirect: '/Facility/FacilityType',
		meta: {title: '设施设备'},
		children: [
			{
				path: '/Facility/FacilityType',
				name: "FacilityType",
				title: "设施设备分类",
				hide: false,
				component: resolve => require(['@/views/Operation/Facility/_facilityType'], resolve),
				meta: {title: '设施设备分类'},
			},
			{
				path: '/Facility/FacilityInfo',
				name: "FacilityInfo",
				title: "设施设备管理",
				hide: false,
				component: resolve => require(['@/views/Operation/Facility/_facilityInfo'], resolve),
				meta: {title: '设施设备管理'},
			},
			{
				path: '/Facility/FacilityPreOrder',
				name: "FacilityPreOrder",
				title: "预约管理",
				hide: false,
				component: resolve => require(['@/views/Operation/Facility/_facilityPreOrder'], resolve),
				meta: {title: '预约管理'},
			}
		]
	},
	{
		path: '/Commission',
		name: "Commission",
		title: "业委会",
		icon: 'cluster',
		hide: false,
		component: resolve => require(['@/views/Operation/Commission'], resolve),
		meta: {title: '业委会'},
	},
	{
		path: '/Scroll',
		name: "Scroll",
		title: "问卷调查",
		icon: 'container',
		hide: false,
		component: resolve => require(['@/views/Operation/Scroll'], resolve),
		meta: {title: '问卷调查'},
	},
	{
		path: '/Scroll/addScroll',
		name: "addScroll",
		title: "新增问卷调查",
		hide: true,
		component: resolve => require(['@/views/Operation/Scroll/addScroll'], resolve),
		meta: {title: '新增问卷调查'},
	},
	{
		path: '/Scroll/editScroll',
		name: "editScroll",
		title: "编辑问卷调查",
		hide: true,
		component: resolve => require(['@/views/Operation/Scroll/editScroll'], resolve),
		meta: {title: '编辑问卷调查'},
	},
	{
		path: '/Scroll/analyze',
		name: "analyze",
		title: "问卷调查报表分析",
		hide: true,
		component: resolve => require(['@/views/Operation/Scroll/analyze'], resolve),
		meta: {title: '问卷调查报表分析'},
	},
	{
		path: '/Vote',
		name: "Vote",
		title: "投票管理",
		icon: 'bar-chart',
		hide: false,
		component: resolve => require(['@/views/Operation/Vote'], resolve),
		meta: {title: '投票管理'},
	},
]