import httpService from "@/request"

// 发送验证码
export function sendTelCode(params) {
	return httpService({
		url: `/sendTelCode`,
		method: 'post',
		data: params,
	})
}
// 登录
export function loginTel(params) {
	return httpService({
		url: `/loginTelCode`,
		method: 'post',
		data: params,
	})
}
// 密码登录
export function loginPWD(params) {
	return httpService({
		url: `/loginPWD`,
		method: 'post',
		data: params,
	})
}
// 退出登录
export function quit(params) {
	return httpService({
		url: `/user/quit`,
		method: 'post',
		data: params,
	})
}

// 获取用户信息
export function userInfo(params) {
	return httpService({
		url: `/user/manageUser/userInfo`,
		method: 'get',
		params
	})
}
