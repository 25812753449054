/*
 * @Author: your name
 * @Date: 2021-10-13 09:28:02
 * @LastEditTime: 2022-01-05 13:44:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /data-show/src/request/index.js
 */
import axios from 'axios';
// import qs from 'qs';
import router from "@/permission"
import { message} from 'ant-design-vue'
import store from '@/store'
// import baseURL from "@/utils/baseURL"
function filterRequestData(obj) {
	let o = {};
	for(let key in obj) {
		let b1 = obj[key] === 0;
		let b2 = obj[key] === false;
		if(obj[key] || b1 || b2) {
			o[key] = obj[key]
		}
	}
	return o;
}
//创建axios的实例
const httpService = axios.create({
	baseURL: process.env.VUE_APP_URL ,// TODO:具体的配置可以根据项目情况而来
	timeout: 5000
})

//axios的拦截--request
httpService.interceptors.request.use(config => {
	const rqParams = filterRequestData(config.params);
	const rqData = filterRequestData(config.data);
	// post 'Content-Type' === 'application/x-www-form-urlencoded'
	config.headers['Content-Type'] = "application/json";
	config.headers['manage-login-token'] = store.getters.getToken;
	// config.headers['device-type'] = "web";
	config.baseURL = process.env.VUE_APP_URL + store.getters.getCommunityCode + '/manage'
	if(config.method === 'post') {
		config.data = rqData;
	} else {
		config.data = rqData;
		config.params = rqParams;
	}
	return config;
},err => {
	Promise.reject(err);// 请求错误处理
})

//4、axios的拦截--response
httpService.interceptors.response.use(response => {
	// TODO:具体的code对应的处理可继续添加修改
	// let data = null;
	let msg = '';
	let res = response.data;
	// console.log(response);
	if(res.code === 200){
		// code = 200;
		// data = res.data;
		// msg = res.msg || "";
		// console.log(msg);
		// console.log(data);
		return res;
	} else if(res.code == 10010) {
		router.replace({
			path: '/login'
		});
	} else {
		msg = res.msg || "";
		message.error(msg);
		return Promise.reject(new Error(msg))
	}
},err => {
	console.log(err)
	// TODO:具体的code对应的处理可继续添加修改
	if(err.response.code === 301){
		//登录过期跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 ---主页(index) 或者 退出到登录前的浏览的页面
		//这样登录页面登录接口成功之后可以进行跳转 主页(index) 或者 退出到登录前的页面： let path = this.$route.query.redirect || '/index.js';   this.$router.push(path);
		setTimeout(() => {
			router.replace({
				path: '/login',
				query: {
					redirect: router.currentRoute.fullPath
				}
			});
		}, 1000);
	}
	return Promise.reject(err);
})

export default httpService