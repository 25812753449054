/*
 * @Author: your name
 * @Date: 2021-11-18 17:21:16
 * @LastEditTime: 2021-11-22 15:45:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/mutations.js
 */
const mutations = {
	setSelectedKeys: (state, log) => {
		state.selectedKeys = log;
		let str = JSON.stringify(log);
		sessionStorage.setItem('selectedKeys', str);
	},
	setOpenKeys: (state, log) => {
		state.openKeys = log;
		let str = JSON.stringify(log);
		sessionStorage.setItem('openKeys', str);
	},
	setToken: (state, log) => {
		state.token = log;
		sessionStorage.setItem('token', log);
	},
	setCommunityCode: (state, log) => {
		state.communityCode = log;
		sessionStorage.setItem('communityCode', log);
	},
	setCity (state, data) { // 设置参数
		state.cityList = data
	},
	setUserInfo (state, log) {
		state.userInfo = log
		let str = JSON.stringify(log);
		sessionStorage.setItem('userInfo', str);
	}
}

export default mutations