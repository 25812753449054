<template>
    <a-modal :visible="visible" title="警报">
        <div class="warning-area">
            <a-descriptions :column="1">
                <a-descriptions-item label="报警类型">{{alarmData.type}}</a-descriptions-item>
                <a-descriptions-item label="报警号">{{alarmData.alarmNo}}</a-descriptions-item>
                <a-descriptions-item label="报警人手机号">{{alarmData.deviceNo}}</a-descriptions-item>
                <a-descriptions-item label="报警人名称">{{alarmData.deviceName}}</a-descriptions-item>
                <a-descriptions-item label="报警时间">{{alarmData.time}}</a-descriptions-item>
                <a-descriptions-item label="报警内容">{{alarmData.alarmContent}}</a-descriptions-item>
            </a-descriptions>
        </div>
        <a-checkbox v-model="alarmCheck" @change="checkChange">我已知晓该警报</a-checkbox>
        <template slot="footer">
            <a-button type="danger" @click="confirm" :disabled="confirmDisabled">确定</a-button>
        </template>
    </a-modal>
</template>

<script>
import store from "@/store";
export default {
    data() {
        return {
            //窗口
            visible: false,
            alarmCheck: false,
            confirmDisabled: true,
            //报警设置
            websock: null,
            infoText: '',
            showInfo: false,
            lockReconnect: false,
            alarmBox: [],
            alarmBG: ['', 'bgCC6966', 'bg969696', 'bgCC6966 ', 'bg969696', 'bgCC6966', 'bgCC6966', 'bgCC6966'],
            alarmTime: 0,
            timer: null, // 声音报警定时器
            myAudio: null, // 报警声音控件
            alarmType: ['', '超限报警', '超限预警', '断电报警 ', '离线报警', '设备异常', '上线通知', '来电通知'],
            time1: null, // 连接的延时器
            //报警信息
            alarmData: {
                model: '',
                communityCode: '',
                alarmType: undefined,
                alarmNo: '',
                deviceNo: '',
                deviceName: '',
                time: '',
                alarmContent: ''
            }
        }
    },
    mounted() {
        this.initWebSocket()
    },
    methods: {
        //报警
        initWebSocket: function() {
            let communityCode = store.getters.getCommunityCode;
            let nickName = store.getters.getUserInfo.userName;
            let userId = store.getters.getUserInfo.id;
            let url = 'wss://saas.kaidalai.cn/websocket/manage/'+ communityCode +'/'+ nickName + '/' + userId;
            this.websock = new WebSocket(url)
            this.websock.onopen = this.websocketOnopen
            this.websock.onerror = this.websocketOnerror
            this.websock.onmessage = this.websocketOnmessage
            this.websock.onclose = this.websocketOnclose
        },
        websocketOnopen: function () {
            console.log('页面WebSocket连接成功')
            //心跳检测重置
            // this.heartCheck.reset().start()
        },
        // 连接失败后的回调函数
        websocketOnerror: function (e) {
            console.log('WebSocket连接发生错误', e)
            this.reconnect()
        },
        // 当从服务器接受到信息时的回调函数
        websocketOnmessage: function (e) {
            // console.log('-----接收消息-----');
            // console.log(e.data)
            let data = JSON.stringify(e.data);
            this.alarmData = JSON.parse(data);
            if(this.alarmData.alarmType) {
                this.visible = true;
            }
        },
        websocketOnclose: function (e) {
            // console.log('connection closed (' + e.code + ')')
            // this.reconnect()
        },
        //重新连接
        reconnect() {
            let context = this
            if (context.lockReconnect) return
            context.lockReconnect = true
            //没连接上会一直重连，设置延迟避免请求过多
            clearTimeout(this.time1);
            this.time1 = setTimeout(function () {
                // console.info('尝试重连...')
                context.initWebSocket()
                context.lockReconnect = false
            }, 5000)
        },

        /////////
        //窗口控制
        checkChange() {
            if(this.alarmCheck == true) {
                this.confirmDisabled = false;
            } else {
                this.confirmDisabled = true;
            }
        },
        confirm() {
            this.visible = false
        }
    }
}
</script>

<style lang="less">

</style>