<!--
 * @Author: your name
 * @Date: 2021-11-18 17:27:08
 * @LastEditTime: 2021-11-24 15:34:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Layout/index.vue
-->
<template>
    <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
            <div class="logo"><img class="m1" src="../../assets/images/logo.png" /><span class="s1" v-if="!collapsed">小蜜蜂SaaS</span></div>
            <yMenu :csd="collapsed" :num='tagActive'></yMenu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header style="background: #fff; padding: 0; height: 42px">
                <div class="h-d1">
                    <div v-for="(item,index) in menuTags" :key="index" :class="tagActive === index ? 'h-d1-item h-d1-item-active' : 'h-d1-item'" @click="handlerTags(index)"><span class="s1">{{item.label}}</span></div>
                </div>
                <div class="h-d2">
                    <a-icon class="h-d2-c" style="color: #e27473" type="logout" @click="logout"/>
                    <a-icon class="h-d2-c" style="color: #b7b7b7" type="question-circle" />
                    <a-icon class="h-d2-c" type="alert" />
                    <a-icon class="h-d2-c" style="color: #979a96;margin-right: 7px;" type="user" />
                    <span class="h-d2-s">{{getUserInfo.actualName}}</span>
                </div>
            </a-layout-header>
            <div class="l-h-d1">
                <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)" />
                <a-breadcrumb>
                    <a-breadcrumb-item v-for="(item,index) in tags" :key="index"><a class="abt" @click="handlerBread(item)">{{item.meta.title}}</a></a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <a-layout-content :style="{ margin: '10px', padding: '13px', background: '#fff'}">
                <router-view></router-view>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import yMenu from "./yMenu";
import { quit } from "@/api/public/login"
export default {
	components: {
		yMenu,
	},
	mounted(){
		this.$router.push({ path: JSON.parse(sessionStorage.getItem('selectedKeys'))[0] });
		this.tagActive = Number(sessionStorage.getItem('index'))
	},
	watch: {
		$route: {
			handler(val) {
				// console.log(val);
				this.tags = val.matched;
			},
			immediate: true,
		},
		tagActive:{
			handler(val) {
				// console.log(val);
				sessionStorage.setItem('modal',this.menuTags[val].value)
			},
			immediate: true,
		}
	},
	data() {
		return {
			collapsed: false,
			tagActive: 0,
			tags: [],
			menuTags: [
				{
					label: "基础功能",
					value: "basic"
				},
				{
					label: "智慧商城",
					value: "shop"
				},
				{
					label: "缴费管理",
					value: "payment"
				},
				{
				    label: "运营管理",
				    value: "operation"
				},
				// {
				//     label: "设置",
				//     value: "setting"
				// }
			],
		};
	},
	methods: {
		handlerBread(row) {
			let path = row.path || "/";
			this.$router.push(path);
		},
		handlerTags(n) {
			this.tagActive = n;
			sessionStorage.setItem('index',n)
		},
		async logout(){
			let res = await quit()
			if(res.code === 200){
				this.$message.success(res.msg)
				this.$router.push({name:'Login'});
			}else{
				this.$message.error(res.msg)
			}
		}
	},
};
</script>
<style lang="less" scoped>
#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    .m1 {
       height: 24px;
       background: #fff;
       padding: 3px;
       border-radius: 2px;
    }
    .s1 {
        display: block;
        color: #d6d6d6;
        font-size: 18px;
        margin-left: 10px;
        font-weight: 500;
    }
}
.h-d1 {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    line-height: 1;
    .h-d1-item {
        width: 102px;
        height: 100%;
        text-align: center;
        line-height: 42px;
        cursor: pointer;
        .s1 {
            display: inline-block;
            height: 100%;
            border-bottom: 2px solid transparent;
        }
    }
    .h-d1-item-active {
        .s1 {
             border-bottom: 2px solid #5679b1;
        }

    }
}
.h-d2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .h-d2-c {
        display: inline-block;
        margin-left: 24px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
    }
    .h-d2-s {
        display: inline-block;
        margin-right: 24px;
    }
}

.l-h-d1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}
.abt {
    cursor: pointer;
}
</style>