<template>
  <a-cascader
    :options="cityList"
    placeholder="请选择"
    :field-names="{
      label: 'name',
      value: 'id',
      children: 'cityList',
    }"
    @change="change"
    style="width: 60%"
  ></a-cascader>
</template>

<script>
import store from "@/store";
export default {
	data(){
		return{
			cityList:[]
		}
	},
	methods: {
		change(data){
			this.$emit('onchange',data)
		}
	},
	mounted() {
		if(store.getters.getCity.length===0){
			this.$axios({
				method:'get',
				url:process.env.VUE_APP_URL+'manage/city/allCity',//【api不需要communityCode】
			}).then((response) =>{        
				this.cityList = response.data.data
				this.setCity(response.data.data);
			}).catch((error) =>{
				console.log(error)       //请求失败返回的数据
			})
		}
	}
};
</script>

<style>
</style>