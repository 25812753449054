/*
 * @Author: your name
 * @Date: 2021-11-24 14:50:31
 * @LastEditTime: 2021-11-24 14:50:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /LittleBeeSaas/src/router/smartMall.js
 */
export default [
	{
		path: '/GoodsManage',
		name: "GoodsManage",
		title: "商品管理",
		icon: 'home',
		hide: false,
		component: resolve => require(['@/views/Shop/GoodsManage'], resolve),
		redirect: '/GoodsManage/GoodsList',
		meta: {title: '商品管理'},
		children: [
			{
				path: '/GoodsManage/GoodsList',
				name: "GoodsList",
				title: "商品列表",
				hide: false,
				component: resolve => require(['@/views/Shop/GoodsManage/_goodsList'], resolve),
				meta: {title: '商品列表'},
			},
			{
				path: '/GoodsManage/SelfGoodsList',
				name: "SelfGoodsList",
				title: "自营商品列表",
				hide: false,
				component: resolve => require(['@/views/Shop/GoodsManage/_selfGoodsList'], resolve),
				meta: {title: '自营商品列表'},
			},
			{
				path: '/GoodsManage/PointGoods',
				name: "PointGoods",
				title: "兑换商品设置",
				hide: false,
				component: resolve => require(['@/views/Shop/GoodsManage/_pointGoods'], resolve),
				meta: {title: '兑换商品设置'},
			},
			{
				path: '/GoodsManage/JcookList',
				name: "JcookList",
				title: "jcook商品库",
				hide: false,
				component: resolve => require(['@/views/Shop/GoodsManage/_jcookList'], resolve),
				meta: {title: 'jcook商品库'},
			},
			{
				path: '/GoodsManage/GoodsCate',
				name: "GoodsCate",
				title: "商品分类",
				hide: false,
				component: resolve => require(['@/views/Shop/GoodsManage/_goodsCate'], resolve),
				meta: {title: '商品分类'},
			},
			// {
			// 	path: '/GoodsManage/GoodsCate',
			// 	name: "GoodsCate",
			// 	title: "商品审核",
			// 	hide: false,
			// 	component: resolve => require(['@/views/Shop/GoodsManage/_goodsCate'], resolve),
			// 	meta: {title: '商品分类'},
			// },
			{
				path: '/GoodsManage/Brand',
				name: "Brand",
				title: "品牌资质",
				hide: false,
				component: resolve => require(['@/views/Shop/GoodsManage/_brand'], resolve),
				meta: {title: '品牌资质'},
			},
		]
	},
	{
		path: '/OrderManage',
		name: "OrderManage",
		title: "订单列表",
		hide: false,
		icon: 'container',
		component: resolve => require(['@/views/Shop/OrderManage'], resolve),
		meta: {title: '订单列表'}
	},
	{
		path: '/OrderDetail',
		name: "OrderDetail",
		title: "订单详情",
		hide: true,
		icon: 'container',
		component: resolve => require(['@/views/Shop/OrderManage/depend/detail'], resolve),
		meta: {title: '订单详情'}
	},
	{
		path: '/SupplierManage',
		name: "SupplierManage",
		title: "供应商管理",
		hide: false,
		icon: 'contacts',
		component: resolve => require(['@/views/Shop/SupplierManage'], resolve),
		meta: {title: '供应商管理'}
	},
	{
		path: '/StoreManage',
		name: "StoreManage",
		title: "店铺管理",
		hide: false,
		icon: 'shop',
		component: resolve => require(['@/views/Shop/StoreManage'], resolve),
		meta: {title: '店铺管理'}
	},
	{
		path: '/SwiperManage',
		name: "SwiperManage",
		title: "商城轮播图管理",
		hide: false,
		icon: 'bars',
		component: resolve => require(['@/views/Shop/SwiperManage'], resolve),
		meta: {title: '商城轮播图管理'}
	},
	{
		path: '/ScoreSetting',
		name: "ScoreSetting",
		title: "积分设置",
		hide: false,
		icon: 'crown',
		component: resolve => require(['@/views/Shop/ScoreSetting'], resolve),
		meta: {title: '积分设置'}
	},
]