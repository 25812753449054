<template>
  <div>
    <a-upload
      :action="`${$upload}`"
      accept=".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
      list-type="picture-card"
      :headers="uploadHeaders"
      :file-list="fileList"
      @preview="handlePreview"
      @change="handleChange"
      :beforeUpload="beforeUpload"
    >
      <div v-if="fileList.length < limit">
        <a-icon type="plus" />
        <div class="ant-upload-text">上传图片</div>
      </div>
    </a-upload>
    <imgModal
      :previewVisible="previewVisible"
      :previewImage="previewImage"
      @handleCancel="handleCancel"
    />
  </div>
</template>

<script>
import imgModal from "./imgModal.vue";
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}
import store from "@/store";
export default {
	components: {
		imgModal,
	},
	props: {
		fileList: Array,
		limit: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			previewVisible: false,
			previewImage: "",
			uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
		};
	},
	//
	methods: {
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		handleCancel() {
			this.previewVisible = false;
		},
		handleChange({ fileList }) {
			this.$emit("handleChange", fileList);
		},
		// 限制50mb
		beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>

<style></style>
