<template>
  <div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
export default {
	props:{
		previewVisible:{
			type:Boolean,
			default:false,
		},
		previewImage:{
			type:String,
		}
	},
	methods:{
		handleCancel() {
			this.$emit('handleCancel')
		},
	}
}
</script>

<style>

</style>