<template>
  <div class="main">
    <div style="margin-bottom: 16px">
      <!-- 批量操作 -->
      <a-select
        type="primary"
        v-model="activeAction"
        :disabled="!hasSelected"
        :loading="loading"
        style="width: 120px"
        @change="Actions"
        placeholder="请选择操作"
        v-if="ActionsList.length!=0"
      >
        批量
        <a-select-option v-for="item in ActionsList" :key="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 1400 }"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
      <!-- 外头传进来的操作栏信息，data为表格循环出来的当前行数据信息 -->
      <span slot="action" slot-scope="text, row">
        <slot name="actionBox" :data="row"></slot>
      </span>
      <span slot="tags" slot-scope="tags">
      <a-tag
        v-for="tag,index in tags"
        :key="index"
      >
        {{ formatter(tag) }}
      </a-tag>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
	data() {
		return {
			// 表格分页
			pagination: {
				showTotal: (total) => `共 ${total} 条`,
				showSizeChanger: true,
				showQuickJumper: true,
			},
			activeAction:undefined,
			/**
       * 
       * 
       * customRender: function (gender) {
            if (gender === 1) {
              return "是";
            } else {
              return "否";
            }
          },
      */
			loading: false,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	computed: {
		// 是否选择selection
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
	// 组件属性
	props: {
		columns: Array,
		tableData: Array,
		ActionsList: Array,
		// activeAction:Number
	},
	methods: {
		// 选择selection
		selectionChoosed(selectedRowKeys, data) {
			this.selectedRowKeys = selectedRowKeys;
			this.$emit("selectionChoosed", data);
		},
		// 页码切换
		handleTableChange(val) {
			this.$emit("handleTableChange", val);
		},
		// 功能
		Actions(val) {
			this.$emit("Actions", val);
			this.activeAction = undefined
		},
		formatter(val){
			this.$emit("formatter", val);
		}
	},
};
</script>

<style lang="less" scoped>
#commonTable {
  margin: 24px;
}
</style>
