/*
 * @Author: your name
 * @Date: 2021-11-18 17:21:01
 * @LastEditTime: 2021-11-22 15:44:58
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/state.js
 */
const state = {
	selectedKeys: JSON.parse(sessionStorage.getItem('selectedKeys')) || ['/'],
	openKeys: JSON.parse(sessionStorage.getItem('openKeys')) || [],
	token: sessionStorage.getItem('token') || "",
	communityCode: sessionStorage.getItem('communityCode') || "",
	cityList:[],
	userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || {},
}
export default state