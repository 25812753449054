/*
 * @Author: your name
 * @Date: 2021-11-22 15:34:24
 * @LastEditTime: 2021-11-22 15:48:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/mixins/index.js
 */
import { mapGetters, mapActions } from "vuex";

export default {
	install(Vue) {
		Vue.mixin({
			computed: {
				...mapGetters(['getToken','getCommunityCode','getSelectedKeys', 'getOpenKeys','getCity','getUserInfo'])
			},
			methods: {
				...mapActions(['setToken','setCommunityCode','setSelectedKeys', 'setOpenKeys','setCity','setUserInfo'])
			}
		})
	}
}
