import NProgress from "nprogress"
import "nprogress/nprogress.css"
import router from "@/router"
import store from '@/store'

const whitePath = [ '/login','/rule','/yszc','/fromPlatform']
// 判断登录
router.beforeEach((to, from, next) => {
	NProgress.start()
	const token = store.getters.getToken;
	if(!token && !whitePath.includes(to.path)) {
		next('/login');
	} else {
		next();
		NProgress.done()
	}
})

router.afterEach(() => {
	NProgress.done()
})

export default router