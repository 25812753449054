/*
 * @Author: your name
 * @Date: 2021-11-18 17:30:38
 * @LastEditTime: 2021-11-26 16:15:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/index.js
 */
import Vue from 'vue'
import Router from "vue-router"
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);

// 枚举文件
// const files = require.context('./', true, /\.js/)
// let configRouters = []
// console.log(files.keys());
// files.keys().forEach((key, index) => {
// 	console.log(files(key).default,files);
// })
import Layout from "@/views/Layout"
import basic from "./basic" // 基础功能
import payment from "./payment" // 缴费管理
import shop from "./shop" // 智慧商城
import operation from "./operation" // 运营管理
import setting from "./setting" // 设置

const router = [
	{
		path: "/",
		name: "basic",
		title: "基础",
		component: Layout,
		redirect: '/Employee',
		meta: {title: '首页'},
		children: [
			...basic,
		]
	},
	{
		path: "/shop",
		name: "shop",
		title: "智慧商城",
		component: Layout,
		redirect: '/GoodsManage/GoodsList',
		meta: {title: '智慧商城'},
		children: [
			...shop,
		]
	},
	{
		path: "/payment",
		name: "payment",
		title: "缴费管理",
		component: Layout,
		redirect: '/ChargeStandardManage',
		meta: {title: '缴费管理'},
		children: [
			...payment,
		]
	},
	{
		path: "/operation",
		name: "operation",
		title: "运营管理",
		component: Layout,
		redirect: '/clientSet',
		meta: {title: '运营管理'},
		children: [
			...operation,
		]
	},
	{
		path: "/setting",
		name: "setting",
		title: "设置",
		component: Layout,
		redirect: '/basicSet',
		meta: {title: '设置'},
		children: [
			...setting,
		]
	},
	{
		path: "/rule",
		name: "Rule",
		title: "用户协议",
		component: resolve => require(['@/views/Rule'], resolve)
	},
	{
		path: "/yszc",
		name: "yszc",
		title: "隐私政策",
		component: resolve => require(['@/views/Yszc'], resolve)
	},
	{
		path: "/fromPlatform",
		name: "fromPlatform",
		title: "跳转登录",
		component: resolve => require(['@/views/FromPlatform'], resolve)
	},
	{
		path: "/login",
		name: "Login",
		title: "登录",
		component: resolve => require(['@/views/Login'], resolve)
	}
]

export default new Router({
	routes: router
})