<template>
  <div class="form-box">
    <a-form-model ref="ruleForm" :model="form" layout='inline' v-if="itemList.length > 0">
      <a-form-model-item
        v-for="item in itemList"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        style="margin-left:32px"
      >
        <a-input v-if="item.type === 'input'" v-model="form[item.prop]" :placeholder="item.placeholder" />
        <a-select
          v-model="form[item.prop]"
          v-else-if="item.type === 'select'"
          :placeholder="item.placeholder"
        >
          <a-select-option
            v-for="option in item.option"
            :key="option.id"
            :value="option.id"
            >{{ option.name }}</a-select-option
          >
        </a-select>
        <a-range-picker v-else-if="item.type === 'time'" v-model="form[item.prop]" value-format="YYYY/MM/DD HH:mm:ss"  @change="changeTime($event,item.start,item.end)" />
      </a-form-model-item>
    </a-form-model>
    <div class="btn-box"><a-button type="primary" @click="getSearch"> 查询 </a-button>
        <a-button style="margin-left: 10px" @click="resetForm"> 重置 </a-button></div>
  </div>
</template>

<script>
export default {
	props: {
		formItem:{
			type:Array,
		}
	},
	watch: {
		formItem: {
			handler(val){
				this.itemList = val
				let obj = {}
				for(let k of val){
					if(k.type==='time'){
						obj[k.start] = undefined
						obj[k.end] = undefined
					}else{
						obj[k.prop] = undefined;
					}
				}
				this.form = obj;
			},
			immediate: true
		},
	},
	data() {
		return {
			itemList: [],
			form: {},
		};
	},
	methods: {
		getSearch() {
			let obj = {}
			for(let k in this.form){
				if (!Array.isArray(this.form[k])) {
					obj[k] = this.form[k]
				}else{
					console.log(k);
					let start  = k + 'StartTime'
					let end  = k + 'EndTime'
					obj[start] = this.form[k][0]
					obj[end] = this.form[k][1]
				}
			}
			let obj1 = Object.assign({}, obj)
			console.log(obj1);
			this.$emit('getSearch', obj1)
		},
		resetForm() {
			this.$refs.ruleForm.resetFields();
			this.getSearch()
		},
		changeTime(data,start,end){
			this.$emit('changeTime', data,start,end)
			this.form[start] = data[0]
			this.form[end] = data[1]
		}
	},
};
</script>

<style lang="less" scoped>
.form-box {
  text-align: left;
  background: #f9f9f9;
  padding-top: 25px;
  padding-bottom: 1px;
}
.btn-box{
  margin:10px 0 15px 100px;
}
/deep/.ant-input{
  min-width: 150px;
}
/deep/.ant-select{
  min-width: 150px;
}
</style>