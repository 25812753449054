export default [
	{
		path: '/basicSet',
		name: "basicSet",
		title: "基础设置",
		hide: false,
		icon: 'container',
		component: resolve => require(['@/views/Set/basicSet'], resolve),
		meta: {title: '基础设置'}
	},
]