import Vue from 'vue'
import App from './App.vue'
import './styles/index.less';
import mixins from "@/mixins"
import Antd from 'ant-design-vue';
// import {Cascader} from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// import moment from 'moment';
import util from '@/utils/util.js'
import 'ant-design-vue/dist/antd.css';

Vue.use(util);
Vue.use(Antd);
Vue.use(mixins);
// Vue.use(Cascader);
import store from "@/store";
import router from "@/permission";

// Vue.prototype.$moment = moment
const communityCode = store.getters.getCommunityCode;
Vue.prototype.$baseUrl = process.env.VUE_APP_URL + communityCode + '/manage'
Vue.config.productionTip = false
import commonTable from './components/table'
import commonUpload from './components/upload/index.vue'
import allCity from './components/allCity/index.vue'
import searchForm from './components/searchForm/index.vue'
import alarmModal from './components/alarmModal/index.vue'

// 注册组件
Vue.component('commonTable', commonTable)
Vue.component('commonUpload', commonUpload)
Vue.component('allCity', allCity)
Vue.component('searchForm', searchForm)
Vue.component('alarmModal', alarmModal)

new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')
